@tailwind base;
@tailwind components;
@tailwind utilities;

/* Prevent text selection for the entire document */
body {
  user-select: none;
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  -webkit-user-select: none; /* Safari */
}

/* Allow text selection within certain elements */
.selectable {
  user-select: auto;
  -moz-user-select: auto; /* Firefox */
  -ms-user-select: auto; /* Internet Explorer/Edge */
  -webkit-user-select: auto; /* Safari */
}